:root {
    --color-buttons-primary: rgb(9, 88, 217);
    --color-buttons-secondary: rgb(230, 244, 255);
    --color-buttons-black: #020617;
    --color-buttons-white: #fafafa;
    --color-buttons-alert: #fee2e2;
    --color-buttons-alert100: #fecaca;
    --color-buttons-alert400: #f87171;

    --color-texts-primary: #fafafa;
    --color-texts-secondary: #020617;
    --color-texts-tertiary: #1677ff;
    --color-texts-tertiary400: rgb(9, 88, 217);
    --color-texts-tertiary300: #7da5b3;
    --color-texts-tertiary200: rgb(105, 177, 255);
    --color-texts-secondary200: #020617;
    --color-texts-secondary300: #64748b;
    --color-texts-red400: #f87171;

    --color-backgrounds-primary500: rgb(0, 44, 140);
    --color-backgrounds-primary400: rgb(9, 88, 217);
    --color-backgrounds-primary300: rgb(22, 119, 255);
    --color-backgrounds-primary200: rgb(105, 177, 255);
    --color-backgrounds-primary100: rgb(230, 244, 255);
    --color-backgrounds-secondary: #f8fafc;
    --color-backgrounds-black: #020617;
    --color-backgrounds-white: #ffffff;
    --color-backgrounds-selected100: #1677ff;
    --color-backgrounds-blue: #38bdf8;
    --color-backgrounds-gold: gold;
    --color-backgrounds-border: #edf2f7;
    --color-backgrounds-settings100: #fef9e7;
    --color-backgrounds-settings500: #ffd700;
    --color-backgrounds-secondaryChat: rgba(240, 240, 240, 0.9);
    --color-backgrounds-slate: #f7fafc;

    --color-icons-primary: rgb(105, 177, 255);
    --color-icons-secondary: #f8fafc;
    --color-icons-primary100: #add8e6;
    --color-icons-tertiary100: lightgrey;
    --color-icons-tertiary200: grey;
    --color-icons-tertiary: rgba(190, 190, 190, 1);
    --color-icons-info: #38bdf8;
}
