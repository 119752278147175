.theme-orange {
    --color-buttons-primary: #ea580c;
    --color-buttons-secondary: #ffedd5;
    --color-buttons-black: #1e293b;
    --color-buttons-white: #fafafa;
    --color-buttons-alert: #fee2e2;
    --color-buttons-alert100: #fecaca;
    --color-buttons-alert400: #ef4444;

    --color-texts-primary: #fafafa;
    --color-texts-secondary: #1e293b;
    --color-texts-tertiary: #c2410c;
    --color-texts-tertiary400: #ea580c;
    --color-texts-tertiary300: #f97316;
    --color-texts-tertiary200: #fdba74;
    --color-texts-secondary200: #1e293b;
    --color-texts-secondary300: #64748b;
    --color-texts-red400: #ef4444;

    --color-backgrounds-primary500: #9a3412;
    --color-backgrounds-primary400: #c2410c;
    --color-backgrounds-primary300: #ea580c;
    --color-backgrounds-primary200: #fdba74;
    --color-backgrounds-primary100: #ffedd5;
    --color-backgrounds-secondary: #ffedd527; /* Pale Orange */
    --color-backgrounds-black: #1e293b;
    --color-backgrounds-white: #ffffff;
    --color-backgrounds-selected100: #ea580c;
    --color-backgrounds-blue: #38bdf8;
    --color-backgrounds-gold: gold;
    --color-backgrounds-border: #e5e7eb;
    --color-backgrounds-settings100: #fef9e7;
    --color-backgrounds-settings500: #ffd700;
    --color-backgrounds-secondaryChat: rgba(240, 240, 240, 0.9);
    --color-backgrounds-slate: #f7fafc;

    --color-icons-primary: #fdba74;
    --color-icons-secondary: #f8fafc;
    --color-icons-primary100: #ffedd5;
    --color-icons-tertiary100: lightgrey;
    --color-icons-tertiary200: grey;
    --color-icons-tertiary: rgba(190, 190, 190, 1);
    --color-icons-info: #f97316;
}
