.theme-violet {
    --color-buttons-primary: #7c3aed; /* violet-600 */
    --color-buttons-secondary: #ede9fe; /* violet-100 */
    --color-buttons-black: #020617; /* zinc-900 */
    --color-buttons-white: #fafafa;
    --color-buttons-alert: #fee2e2;
    --color-buttons-alert100: #fecaca;
    --color-buttons-alert400: #f87171;

    --color-texts-primary: #fafafa;
    --color-texts-secondary: #020617; /* zinc-900 */
    --color-texts-tertiary: #8b5cf6; /* violet-500 */
    --color-texts-tertiary400: #7c3aed; /* violet-600 */
    --color-texts-tertiary300: #a78bfa; /* violet-400 */
    --color-texts-tertiary200: #c4b5fd; /* violet-300 */
    --color-texts-secondary200: #020617; /* zinc-900 */
    --color-texts-secondary300: #64748b;
    --color-texts-red400: #f87171;

    --color-backgrounds-primary500: #5b21b6; /* violet-800 */
    --color-backgrounds-primary400: #6d28d9; /* violet-700 */
    --color-backgrounds-primary300: #7c3aed; /* violet-600 */
    --color-backgrounds-primary200: #a78bfa; /* violet-400 */
    --color-backgrounds-primary100: #ede9fe; /* violet-100 */
    --color-backgrounds-secondary: #f3e8ff1d; /* Pale Violet */
    --color-backgrounds-black: #020617; /* zinc-900 */
    --color-backgrounds-white: #ffffff;
    --color-backgrounds-selected100: #8b5cf6; /* violet-500 */
    --color-backgrounds-blue: #38bdf8;
    --color-backgrounds-gold: gold;
    --color-backgrounds-border: #e5e7eb;
    --color-backgrounds-settings100: #fef9e7;
    --color-backgrounds-settings500: #ffd700;
    --color-backgrounds-secondaryChat: rgba(240, 240, 240, 0.9);
    --color-backgrounds-slate: #f7fafc;

    --color-icons-primary: #c4b5fd; /* violet-300 */
    --color-icons-secondary: #f8fafc;
    --color-icons-primary100: #ddd6fe; /* violet-200 */
    --color-icons-tertiary100: lightgrey;
    --color-icons-tertiary200: grey;
    --color-icons-tertiary: rgba(190, 190, 190, 1);
    --color-icons-info: #38bdf8;
}
