/* Switch Button */
.ant-switch.ant-switch-checked {
    background: var(--color-buttons-primary) !important;
}

.ant-switch {
    background: var(--color-buttons-secondary) !important;
}

/* Slider */
.ant-slider .ant-slider-track {
    background-color: var(--color-buttons-primary) !important;
    height: 7px;
    border-radius: 10px;
}

.ant-slider-horizontal .ant-slider-rail {
    width: 100%;
    height: 7px;
    background: var(--color-buttons-secondary) !important;
    border-radius: 10px;
}

.ant-slider .ant-slider-handle::after {
    box-shadow: 0 0 0 2px var(--color-buttons-primary) !important;
    width: 12px;
    height: 12px;
}

.ant-slider .ant-slider-handle:hover::after {
    box-shadow: 0 0 0 2px var(--color-buttons-primary) !important;
    width: 13px;
    height: 13px;
}

/* Table Header */
.ant-table-thead > tr > th {
    background-color: var(--color-backgrounds-secondary) !important;
}

/* Pagination */
.ant-pagination-item-active {
    border: solid 1px var(--color-buttons-primary) !important;
    border-radius: 100% !important;
    font-weight: 600 !important;
    background-color: var(--color-buttons-secondary) !important;
    color: var(--color-buttons-primary) !important;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05) !important;
}

.ant-pagination-item {
    background-color: var(--color-buttons-white) !important;
    border-radius: 100% !important;
}

.ant-pagination-item:hover {
    background-color: var(--color-buttons-secondary) !important;
    border-radius: 100% !important;
    color: var(--color-buttons-primary) !important;
    font-weight: 600 !important;
}

.ant-pagination-item-active a {
    font-weight: 600 !important;
    color: var(--color-buttons-primary) !important;
}

.ant-pagination-item a:hover {
    font-weight: 600 !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    font-weight: 600 !important;
    background-color: var(--color-buttons-secondary) !important;
    border-radius: 100% !important;
}

/* General Pagination */
.ant-pagination {
    cursor: default !important;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 16px;
}

/* Progress Bar */
:where(
        .css-dev-only-do-not-override-gzal6t
    ).ant-progress.ant-progress-status-success
    .ant-progress-bg {
    background-color: var(--color-icons-primary) !important;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-progress .ant-progress-bg {
    background-color: var(--color-icons-info) !important;
}

/* Mini Pagination Spacing */
:where(.css-dev-only-do-not-override-d2lrxs).ant-pagination.ant-pagination-mini
    .ant-pagination-item {
    margin: 0px 3px !important;
}

/* List Header/Footer */
:where(.css-dev-only-do-not-override-d2lrxs).ant-list .ant-list-header,
:where(.css-dev-only-do-not-override-d2lrxs).ant-list .ant-list-footer {
    padding-block: 4px;
}
