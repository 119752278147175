/* Theme: Green */
.theme-green {
    --color-buttons-primary: rgb(5, 150, 105); /* Deeper Green */
    --color-buttons-secondary: rgb(209, 250, 229); /* Light Green */
    --color-buttons-black: #1e293b;
    --color-buttons-white: #fafafa;
    --color-buttons-alert: #fee2e2;
    --color-buttons-alert100: #fecaca;
    --color-buttons-alert400: #f87171;

    --color-texts-primary: #fafafa;
    --color-texts-secondary: #1e293b;
    --color-texts-tertiary: #047857; /* Stronger Green */
    --color-texts-tertiary400: #065f46; /* Darker Green */
    --color-texts-tertiary300: #059669; /* Medium Green */
    --color-texts-tertiary200: #16a34a; /* Vivid Green */
    --color-texts-secondary200: #1e293b;
    --color-texts-secondary300: #64748b;
    --color-texts-red400: #f87171;

    --color-backgrounds-primary500: #064e3b; /* Darker Jungle Green */
    --color-backgrounds-primary400: #065f46; /* Deeper Green */
    --color-backgrounds-primary300: #047857; /* Vibrant Green */
    --color-backgrounds-primary200: #059669;
    --color-backgrounds-primary100: #bbf7d0; /* Softer Green */
    --color-backgrounds-secondary: #ecfdf56a; /* Pale Green */
    --color-backgrounds-black: #1e293b;
    --color-backgrounds-white: #ffffff;
    --color-backgrounds-selected100: #059669;
    --color-backgrounds-blue: #10b981; /* Replaced with Green */
    --color-backgrounds-gold: gold;
    --color-backgrounds-border: #d1fae5; /* Light Green Border */
    --color-backgrounds-settings100: #fef9e7;
    --color-backgrounds-settings500: #ffd700;
    --color-backgrounds-secondaryChat: rgba(240, 240, 240, 0.9);
    --color-backgrounds-slate: #f0fdf4; /* Faint Green */

    --color-icons-primary: #16a34a; /* Strong Green */
    --color-icons-secondary: #f8fafc;
    --color-icons-primary100: #86efac; /* Bright Green */
    --color-icons-tertiary100: lightgrey;
    --color-icons-tertiary200: grey;
    --color-icons-tertiary: rgba(190, 190, 190, 1);
    --color-icons-info: #059669;
}
