/* Theme: Turquoise */
.theme-turquoise {
    --color-buttons-primary: rgb(20, 184, 166); /* teal-500 (turquoise tone) */
    --color-buttons-secondary: rgb(204, 251, 241); /* teal-100 */
    --color-buttons-black: #1e293b;
    --color-buttons-white: #fafafa;
    --color-buttons-alert: #fee2e2;
    --color-buttons-alert100: #fecaca;
    --color-buttons-alert400: #f87171;

    --color-texts-primary: #fafafa;
    --color-texts-secondary: #1e293b;
    --color-texts-tertiary: #0d9488; /* teal-600 */
    --color-texts-tertiary400: #14b8a6; /* teal-500 */
    --color-texts-tertiary300: #2dd4bf; /* teal-400 */
    --color-texts-tertiary200: #5eead4; /* teal-300 */
    --color-texts-secondary200: #1e293b;
    --color-texts-secondary300: #64748b;
    --color-texts-red400: #f87171;

    --color-backgrounds-primary500: #115e59; /* teal-800 */
    --color-backgrounds-primary400: #0f766e; /* teal-700 */
    --color-backgrounds-primary300: #0d9488; /* teal-600 */
    --color-backgrounds-primary200: #14b8a6; /* teal-500 */
    --color-backgrounds-primary100: #ccfbf1; /* teal-100 */
    --color-backgrounds-secondary: #ccfbf11f; /* Pale Turquoise */
    --color-backgrounds-black: #1e293b;
    --color-backgrounds-white: #ffffff;
    --color-backgrounds-selected100: #14b8a6; /* teal-500 */
    --color-backgrounds-blue: #38bdf8;
    --color-backgrounds-gold: gold;
    --color-backgrounds-border: #edf2f7;
    --color-backgrounds-settings100: #fef9e7;
    --color-backgrounds-settings500: #ffd700;
    --color-backgrounds-secondaryChat: rgba(240, 240, 240, 0.9);
    --color-backgrounds-slate: #f7fafc;

    --color-icons-primary: #5eead4; /* teal-300 */
    --color-icons-secondary: #f8fafc;
    --color-icons-primary100: #99f6e4; /* teal-200 */
    --color-icons-tertiary100: lightgrey;
    --color-icons-tertiary200: grey;
    --color-icons-tertiary: rgba(190, 190, 190, 1);
    --color-icons-info: #14b8a6; /* teal-500 */
}
