/* Theme: Sky Blue */
.theme-blue {
    --color-buttons-primary: #38bdf8; /* Sky blue */
    --color-buttons-secondary: #e0f2fe; /* Light sky blue */
    --color-buttons-black: #020617; /* Dark contrast */
    --color-buttons-white: #fafafa;
    --color-buttons-alert: #fee2e2;
    --color-buttons-alert100: #fecaca;
    --color-buttons-alert400: #f87171;

    --color-texts-primary: #fafafa;
    --color-texts-secondary: #020617;
    --color-texts-tertiary: #0ea5e9; /* Bright sky blue */
    --color-texts-tertiary400: #0284c7; /* Slightly darker sky blue */
    --color-texts-tertiary300: #38bdf8; /* Sky blue */
    --color-texts-tertiary200: #7dd3fc; /* Lighter sky blue */
    --color-texts-secondary200: #020617;
    --color-texts-secondary300: #64748b;
    --color-texts-red400: #f87171;

    --color-backgrounds-primary500: #0369a1; /* Darker blue */
    --color-backgrounds-primary400: #0284c7; /* Sky blue */
    --color-backgrounds-primary300: #0ea5e9; /* Brighter sky blue */
    --color-backgrounds-primary200: #38bdf8; /* Lighter sky blue */
    --color-backgrounds-primary100: #e0f2fe; /* Very light blue */
    --color-backgrounds-secondary: #e0f2fe33; /* Light Sky Blue */
    --color-backgrounds-black: #020617;
    --color-backgrounds-white: #ffffff;
    --color-backgrounds-selected100: #0ea5e9;
    --color-backgrounds-blue: #38bdf8;
    --color-backgrounds-gold: gold;
    --color-backgrounds-border: #e5e7eb;
    --color-backgrounds-settings100: #fef9e7;
    --color-backgrounds-settings500: #ffd700;
    --color-backgrounds-secondaryChat: rgba(240, 240, 240, 0.9);
    --color-backgrounds-slate: #f7fafc;

    --color-icons-primary: #7dd3fc; /* Lighter sky blue */
    --color-icons-secondary: #f8fafc;
    --color-icons-primary100: #bae6fd; /* Very light blue */
    --color-icons-tertiary100: lightgrey;
    --color-icons-tertiary200: grey;
    --color-icons-tertiary: rgba(190, 190, 190, 1);
    --color-icons-info: #38bdf8;
}
